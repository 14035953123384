import { AxiosResponse } from 'axios';
import { EmailLoginBody, EmailLoginResponse, AuthRefreshBody, AuthRefreshResponse } from '@workerbase/api/http/auth';
import { LoginMethodResponse } from '@workerbase/types/auth/LoginMethods';

import { api } from 'services/networking/api';
import { setAccessTokenInLocalStorage, setRefreshTokenInLocalStorage } from '../auth/auth.service';

export const AUTH_ENDPOINT = '/api/v1/auth';
export const REFRESH_ENDPOINT = `${AUTH_ENDPOINT}/refresh`;

/* This function is used only for checking the token validity. We don't care about the errors,
   because any error means that the token is either not valid or was not checked because of a backend issue */
export const checkResetPasswordToken = async (resetToken: string): Promise<boolean> => {
  try {
    const {
      data: { success },
    } = await api.get<{ success: boolean }>(`${AUTH_ENDPOINT}/reset-password/${resetToken}`, { incognito: true });

    return success;
  } catch (e) {
    return false;
  }
};

export const setNewPassword = async (password: string, resetToken: string): Promise<boolean> => {
  try {
    const {
      data: { success },
    } = await api.post<{ success: boolean }>(
      `${AUTH_ENDPOINT}/reset-password`,
      {
        password,
        id: resetToken,
      },
      { incognito: true },
    );

    return success;
  } catch (e) {
    return false;
  }
};

export const resetPassword = async (email: string): Promise<boolean> => {
  const {
    data: { success },
  } = await api.post<{ success: boolean }>(`${AUTH_ENDPOINT}/request-reset-password`, { email }, { incognito: true });

  return success;
};

export const makeLoginRequest = async (loginRequestData: EmailLoginBody): Promise<EmailLoginResponse> => {
  // TODO: pull api response generic type from shared
  const response = await api.post<{ data: EmailLoginResponse; success: boolean; errorMessage?: string }>(
    `${AUTH_ENDPOINT}/login`,
    loginRequestData,
    { incognito: true },
  );

  const {
    data: { data: loginData, success, errorMessage },
  } = response;

  if (!success) {
    throw new Error(errorMessage);
  }

  // TODO: consider not keeping token in the localStorage
  setAccessTokenInLocalStorage(loginData.loginToken);
  setRefreshTokenInLocalStorage(loginData.refreshToken);

  return loginData;
};

export const getFreshToken = async (refreshToken: AuthRefreshBody): Promise<AuthRefreshResponse> => {
  const response = await api.post<AuthRefreshBody, AxiosResponse<{ data: AuthRefreshResponse }>>(
    REFRESH_ENDPOINT,
    refreshToken,
    {
      incognito: true,
    },
  );

  return response.data.data;
};

export const getLoginMethods = async (): Promise<LoginMethodResponse[]> => {
  const response = await api.get<{ data: LoginMethodResponse[]; success: boolean; errorMessage?: string }>(
    `${AUTH_ENDPOINT}/login-methods`,
    { incognito: true },
  );

  const {
    data: { data },
  } = response;
  return data;
};
