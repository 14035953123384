import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { FormikErrors, FormikTouched } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { RegexUtils } from '@workerbase/utils/RegexUtils';

import { InputBarcodeRegexType, InputBarcodeOperators } from '@workerbase/domain/input';
import { ComparingConditionOperator, ConditionOperator } from '@workerbase/domain/rule';
import { InputBarcodeRegex } from '@workerbase/types/domain/workinstruction/step/steps';

import { SquareButton } from 'components/SquareButton';
import ActionButton, { ButtonIcons } from 'components/ActionButton';
import { ItemsList, ComponentOption, FieldType, InputTextOption } from 'components/Input/ItemsList';

import { OPERATORS_TRANSLATION_ID_MAPPING } from 'utils/mapping';
import generateRandomId from '../../../utils/generateRandomId';

import styles from './BarcodeFiltersInput.module.scss';

// TODO: this component is very similar to the custompayloadinput and other
// components which need "add filter"
// it might be merged into one main component
interface BarcodeFiltersInputProps {
  filters: InputBarcodeRegex[];
  onChange: (updatedFilters: InputBarcodeRegex[]) => void;
  afterDelete: (updatedFilters: InputBarcodeRegex[]) => void;
  errors?: FormikErrors<InputBarcodeRegex[]>;
  touched?: FormikTouched<InputBarcodeRegex[]>;
  direction?: 'row' | 'column';
}

export const BarcodeFiltersInput: FC<BarcodeFiltersInputProps> = ({
  filters,
  onChange,
  afterDelete,
  errors = [],
  touched = [],
  direction = 'row',
}) => {
  interface Option extends InputBarcodeRegex {
    id: string;
  }

  const intl = useIntl();

  const getItemsComponents = (operator: ConditionOperator, value: string, index: number) => {
    const getRegexTextInputDescription = () => {
      const regexExamplesText: string = intl.formatMessage({
        id: 'global.examples',
      });
      const onlyNumbersText: string = intl.formatMessage({
        id: 'global.only-numbers',
      });
      const lengthTenCharsText: string = intl.formatMessage({
        id: 'global.ten-characters',
      });
      const emailAddressText: string = intl.formatMessage({
        id: 'global.email-address',
      });
      return [
        `${regexExamplesText}:`,
        `${onlyNumbersText}: ^[0-9]*$`,
        `${lengthTenCharsText}: ^[A-Z]{10}$`,
        `${emailAddressText}: /\\S+@\\S+\\.\\S+/`,
      ].join('\n');
    };

    const result: ComponentOption[] = [
      {
        key: 'operator',
        type: FieldType.INPUT_SELECT,
        options: InputBarcodeOperators.map((op) => ({
          value: op,
          label: OPERATORS_TRANSLATION_ID_MAPPING[op]
            ? intl.formatMessage({
                id: OPERATORS_TRANSLATION_ID_MAPPING[op],
              })
            : op,
        })),
      },
      {
        key: 'value',
        type: FieldType.INPUT_TEXT,
        variablesAutocompleteEnabled: true,
        error: touched[index]?.value ? errors[index]?.value : undefined, // pull corresponding error from formik
      },
    ];

    if (operator === ComparingConditionOperator.REGEX) {
      const inputComponentOption = result[1] as InputTextOption;
      inputComponentOption.description = getRegexTextInputDescription();
      if (value && value.length > 0 && !RegexUtils.isValidRegex(value)) {
        inputComponentOption.error = intl.formatMessage({
          id: 'rules.filter.regex-not-valid',
        });
      }
    }
    return result;
  };

  const deleteFilterWithId = (idOfFieldToDelete: string) => {
    const newFilters = filters.filter((filter) => filter.id !== idOfFieldToDelete);
    afterDelete(newFilters);
    onChange(newFilters);
  };

  const editFilterWithId = (updatedFilter: InputBarcodeRegex, idOfFieldToUpdate: string) => {
    const newFields = [...filters];
    const indexOfFieldToUpdate = newFields.findIndex((field) => field.id === idOfFieldToUpdate);
    newFields[indexOfFieldToUpdate] = updatedFilter;
    onChange(newFields);
  };

  const addFilterWithId = () => {
    const newFields = [...filters];
    const newField: Option = {
      value: '',
      operator: ComparingConditionOperator.EQUAL,
      type: InputBarcodeRegexType.INCLUDE,
      id: generateRandomId(),
    };

    newFields.push(newField);
    onChange(newFields);
  };

  return (
    <div>
      {filters.map((filterWithId: InputBarcodeRegex, index) => (
        <div key={filterWithId.id} className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <ItemsList
              direction={direction}
              values={filterWithId}
              components={getItemsComponents(filterWithId.operator, filterWithId.value, index)}
              onChange={(updatedFilter) => {
                editFilterWithId(updatedFilter, filterWithId.id);
              }}
            />
          </div>
          <SquareButton
            variant="danger"
            icon={<DeleteIcon fontSize="small" />}
            onClick={(e) => {
              deleteFilterWithId(filterWithId.id);
            }}
          />
        </div>
      ))}
      <ActionButton
        title={intl.formatMessage({
          id: 'workinstructions.steps.add-filter',
        })}
        icon={ButtonIcons.PLUS}
        onClick={(e) => {
          addFilterWithId();
        }}
      />
    </div>
  );
};
