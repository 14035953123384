import { InputBarcodeOperators } from './InputBarcodeOperators.constant';
import { InputBarcodeRegexType } from './InputBarcodeRegexType';

export class InputBarcodeRegex {
  id: string;

  type: InputBarcodeRegexType;

  value: string;

  operator: (typeof InputBarcodeOperators)[number];
}
