import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import { Sorting, ListConfigs, PropertySelectors } from '@workerbase/types/ListConfig';

import { Workinstruction } from 'services/types/Workinstruction';
import { PaginationMeta } from '@workerbase/types/Response';

import { WorkinstructionsActions } from './actions';
import {
  UpdatePaginationActionPayload,
  UpdateFilteringActionPayload,
  UpdateListConfigActionPayload,
} from '../common/ListConfig/actions';
import { updatePagination, updateSorting, updateFiltering, updateListConfig } from '../common/ListConfig/reducers';

export type WorkinstructionsState = Readonly<{
  workinstructionsById: { [key: string]: Workinstruction };
  listConfigs: ListConfigs;
  currentListItemsIds: string[];
  errorMessage: string | null;
}>;

export const initialState: WorkinstructionsState = {
  workinstructionsById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'description',
        omit: false,
      },
      {
        selector: 'createdAt',
        omit: false,
      },
      {
        selector: 'lastUsedAt',
        omit: false,
      },
      {
        selector: PropertySelectors.LINKS,
        omit: false,
      },
    ],
    sorting: { selector: 'name', sortDirection: SortOrder.ASC },
    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
};

interface GetWorkinstructionsSuccessActionPayload {
  workinstructions: Workinstruction[];
  meta: PaginationMeta;
}

interface GetWorkinstructionSuccessActionPayload {
  workinstruction: Workinstruction;
}

interface GetWorkinstructionsFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: WorkinstructionsState = initialState, action: AnyAction): WorkinstructionsState => {
  switch (action.type) {
    case WorkinstructionsActions.GET_WORKINSTRUCTIONS_SUCCESS: {
      const payload = action.payload as GetWorkinstructionsSuccessActionPayload;

      const workinstructionsById = payload.workinstructions.reduce((prev, workinstruction) => {
        const updatedWorkinstructions = prev;
        updatedWorkinstructions[workinstruction.id] = workinstruction;
        return prev;
      }, {});

      return {
        ...state,
        workinstructionsById: {
          ...state.workinstructionsById,
          ...workinstructionsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.workinstructions.map((workinstruction) => workinstruction.id),
      };
    }
    case WorkinstructionsActions.GET_WORKINSTRUCTION_BY_ID_SUCCESS: {
      const payload = action.payload as GetWorkinstructionSuccessActionPayload;
      const fetchedWorkinstruction = payload.workinstruction;
      const workinstruction = {
        [fetchedWorkinstruction.id]: fetchedWorkinstruction,
      };

      return {
        ...state,
        workinstructionsById: {
          ...state.workinstructionsById,
          ...workinstruction,
        },
      };
    }
    case WorkinstructionsActions.DELETE_WORKINSTRUCTION_BY_ID_SUCCESS: {
      const workinstructionId: string = action.payload.workinstructionId;

      const workinstructionsById = { ...state.workinstructionsById };
      if (workinstructionsById[workinstructionId]) {
        delete workinstructionsById[workinstructionId];
      }

      return {
        ...state,
        workinstructionsById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== workinstructionId),
      };
    }
    case WorkinstructionsActions.DELETE_WORKINSTRUCTION_BY_ID_FAILURE:
    case WorkinstructionsActions.GET_WORKINSTRUCTIONS_FAILURE:
    case WorkinstructionsActions.GET_WORKINSTRUCTION_BY_ID_FAILURE: {
      const payload = action.payload as GetWorkinstructionsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case WorkinstructionsActions.UPDATE_PAGINATION: {
      const payload = action.payload as UpdatePaginationActionPayload;
      return updatePagination(state, payload);
    }
    case WorkinstructionsActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case WorkinstructionsActions.UPDATE_FILTERING: {
      const payload = action.payload as UpdateFilteringActionPayload;
      return updateFiltering(state, payload);
    }
    case WorkinstructionsActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    case WorkinstructionsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
