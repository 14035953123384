import _ from 'lodash';
import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import { Sorting, ListConfigs } from '@workerbase/types/ListConfig';

import { Project } from 'services/types/Project';

import { PaginationMeta } from '@workerbase/types/Response';

import { ProjectsActions } from './actions';
import {
  UpdatePaginationActionPayload,
  UpdateFilteringActionPayload,
  UpdateListConfigActionPayload,
} from '../common/ListConfig/actions';
import { updatePagination, updateSorting, updateFiltering, updateListConfig } from '../common/ListConfig/reducers';

export type ProjectsState = Readonly<{
  projectsById: { [key: string]: Project };
  listConfigs: ListConfigs;
  currentListItemsIds: string[];
  errorMessage: string | null;
  importButtonDisabled: boolean;
}>;

export const initialState: ProjectsState = {
  projectsById: {},
  listConfigs: {
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'description',
        omit: false,
      },
      {
        selector: 'createdAt',
        omit: false,
      },
      {
        selector: 'roles',
        omit: true,
      },
      {
        selector: 'adminRoles',
        omit: true,
      },
      {
        selector: 'taskCount.open',
        omit: true,
      },
      {
        selector: 'taskCount.inprogress',
        omit: true,
      },
      {
        selector: 'taskCount.suspended',
        omit: true,
      },
    ],
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
    sorting: {
      selector: 'name',
      sortDirection: SortOrder.ASC,
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
  importButtonDisabled: false,
};

interface GetProjectsSuccessActionPayload {
  projects: Project[];
  meta: PaginationMeta;
}

interface GetProjectSuccessActionPayload {
  project: Project;
}

interface GetProjectsFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: ProjectsState = initialState, action: AnyAction): ProjectsState => {
  switch (action.type) {
    case ProjectsActions.GET_PROJECTS_SUCCESS: {
      const payload = action.payload as GetProjectsSuccessActionPayload;

      const projectsById = payload.projects.reduce((prev, project) => {
        const updatedProjects = prev;
        updatedProjects[project.id] = project;
        return prev;
      }, {});

      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          ...projectsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.projects.map((project) => project.id),
      };
    }
    case ProjectsActions.GET_PROJECT_BY_ID_SUCCESS: {
      const payload = action.payload as GetProjectSuccessActionPayload;
      const fetchedProject = payload.project;
      const project = {
        [fetchedProject.id]: fetchedProject,
      };

      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          ...project,
        },
      };
    }
    case ProjectsActions.DELETE_PROJECT_BY_ID_FAILURE: {
      const payload = action.payload as GetProjectsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case ProjectsActions.GET_PROJECT_BY_ID_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    case ProjectsActions.IMPORT_PROJECT:
      return {
        ...state,
        importButtonDisabled: true,
      };
    case ProjectsActions.IMPORT_PROJECT_SUCCESS:
      return {
        ...state,
        importButtonDisabled: false,
      };
    case ProjectsActions.IMPORT_PROJECT_FAILURE: {
      const payload = action.payload as GetProjectsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
        importButtonDisabled: false,
      };
    }
    case ProjectsActions.UPDATE_PAGINATION: {
      const payload = action.payload as UpdatePaginationActionPayload;
      return updatePagination(state, payload);
    }
    case ProjectsActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case ProjectsActions.UPDATE_FILTERING: {
      const payload = action.payload as UpdateFilteringActionPayload;
      return updateFiltering(state, payload);
    }
    case ProjectsActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: _.unionBy(action.payload.properties, state.listConfigs.properties || [], 'selector'),
        },
      };
    case ProjectsActions.UPDATE_TASK_FIELDS: {
      const projectId = action.payload.projectId;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projectId]: {
            ...state.projectsById[projectId],
            config: {
              ...state.projectsById[projectId].config,
              taskDetailsFilter: action.payload.fields,
            },
          },
        },
      };
    }
    case ProjectsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }

    default:
      return state;
  }
};

export default reducer;
