import React, { ReactNode } from 'react';
import { SvgIcon } from '@mui/material';
import {
  TextFields as TextFieldsIcon,
  Checklist as ChecklistIcon,
  SubscriptionsOutlined as SubscriptionsOutlinedIcon,
  FormatColorText as FormatColorTextIcon,
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon,
  QrCodeScanner as QrCodeScannerIcon,
  ViewAgendaOutlined as ViewAgendaOutlinedIcon,
} from '@mui/icons-material';
import { cloneDeep, isEqual } from 'lodash';
import { slugify } from '@workerbase/modules/slugify';
import {
  IBuilderStep,
  DocumentBuilderStepTypes,
  IBuilderStepInputText,
  IBuilderStepInputMedia,
  IBuilderStepInputChecklist,
  IBuilderStepInputScancode,
  IBuilderStepInfoText,
  IBuilderStepInfoMedia,
  IBuilderStepInputNumber,
  BuilderStepInputMediaAccepts,
  IBuilderSection,
  isBuilderSection,
  FlaggingCriteria,
  InputNumberOperators,
  InputChecklistOperators,
} from '@workerbase/domain/document';
import { validateInputNumber } from '@workerbase/utils/validateInputNumber';
import { FilterTypes, isFilterCondition, isFilterGroup } from '@workerbase/domain/common';
import { DocumentVersionUpdateBody } from '@workerbase/api/http/document';
import Icon123 from '../../assets/icons/123.svgr';
import {
  BuilderStepInfoText,
  BuilderStepInfoMedia,
  BuilderStepInputText,
  BuilderStepInputMedia,
  BuilderStepInputNumber,
  BuilderStepInputChecklist,
  BuilderStepInputScancode,
} from '../DocumentBuilderStep';
import {
  DocumentBuilderStepComponentType,
  IBuilderSectionWithStepListItem,
  StepListItem,
  StepListItemMeta,
} from './types';

export const flattenStepList = (steps: StepListItem[]) =>
  steps.reduce<StepListItem[]>((acc, step) => {
    if (isBuilderSection(step?.step)) {
      acc.push(step, ...step.step.steps);
    } else {
      acc.push(step);
    }

    return acc;
  }, []);

export const getStepIdsUsedInDisplayCriteria = (displayCriteria: IBuilderSection['displayCriteria']): string[] => {
  if (!displayCriteria) {
    return [];
  }

  const allIds = displayCriteria.conditions.reduce<string[]>((acc, condition) => {
    if (isFilterGroup(condition)) {
      return [...acc, ...getStepIdsUsedInDisplayCriteria(condition)];
    }

    // return [...acc, condition.name];
    return condition.name ? [...acc, condition.name] : acc;
  }, []);

  return [...new Set(allIds)];
};

export const getStepDisplayCriteriaDiff = (
  newStep: IBuilderStep | IBuilderSectionWithStepListItem,
  oldStep: IBuilderStep | IBuilderSectionWithStepListItem,
) => {
  if (isBuilderSection(newStep) && isBuilderSection(oldStep)) {
    const newConditionedQuestionIds = getStepIdsUsedInDisplayCriteria(newStep.displayCriteria);
    const oldConditionedQuestionIds = getStepIdsUsedInDisplayCriteria(oldStep.displayCriteria);

    if (isEqual(newConditionedQuestionIds, oldConditionedQuestionIds)) {
      return { idsRemoved: undefined, idsAdded: undefined };
    }

    const displayCriteriaDiff = newConditionedQuestionIds.reduce<{
      idsRemoved: string[];
      idsAdded: string[];
    }>(
      (acc, newId) => {
        if (!oldConditionedQuestionIds.includes(newId)) {
          acc.idsAdded.push(newId);
        }
        return acc;
      },
      {
        idsRemoved: oldConditionedQuestionIds.filter((id) => !newConditionedQuestionIds.includes(id)),
        idsAdded: [],
      },
    );

    return displayCriteriaDiff;
  }

  return { idsRemoved: undefined, idsAdded: undefined };
};

export const mapBuilderStepsToStepListItems = (builderSteps: (IBuilderStep | IBuilderSection)[]): StepListItem[] => {
  const stepReferencesById: Record<string, string[]> = {};

  const stepListItems = builderSteps.reduceRight<StepListItem[]>((acc, step) => {
    let sectionReferencedInSteps: string[] = [];

    const stepListItem = {
      step: isBuilderSection(step)
        ? {
            ...step,
            steps: step.steps.map((innerStep) => {
              const referencedInSteps = stepReferencesById[innerStep.id] || [];
              sectionReferencedInSteps = [...sectionReferencedInSteps, ...referencedInSteps];

              return {
                step: innerStep,
                meta: {
                  error: false,
                  referencedInSteps,
                } as StepListItemMeta,
              };
            }),
          }
        : {
            ...step,
            meta: {
              error: false,
              referencedInSteps: stepReferencesById[step.id] || [],
            },
          },
      meta: {
        error: false,
        referencedInSteps: stepReferencesById[step.id] || [],
      },
    };

    if (isBuilderSection(step)) {
      stepListItem.meta.referencedInSteps = [
        ...(stepListItem.meta.referencedInSteps || []),
        ...sectionReferencedInSteps,
      ];
    }

    if (isBuilderSection(step)) {
      const displayCriteriaConditionIds = getStepIdsUsedInDisplayCriteria(step.displayCriteria);

      displayCriteriaConditionIds.forEach((conditionId) => {
        stepReferencesById[conditionId] = [...(stepReferencesById[conditionId] || []), step.id];
      });
    }

    acc.unshift(stepListItem);
    return acc;
  }, []);

  return stepListItems;
};

export const mapBuilderStepsToStepListItemsWithSection = (
  builderSteps: StepListItem<IBuilderStep | IBuilderSectionWithStepListItem>[],
): StepListItem<IBuilderStep | IBuilderSectionWithStepListItem>[] => {
  const stepReferencesById: Record<string, string[]> = {};

  const stepListItems = builderSteps.reduceRight<StepListItem[]>((acc, item) => {
    let sectionReferencedInSteps: string[] = [];

    const isSectionStep = isBuilderSection(item.step);
    let innerHasError = false;

    const stepListItem = {
      step: isSectionStep
        ? {
            ...item.step,
            steps: (item.step as IBuilderSectionWithStepListItem).steps.map((innerStep) => {
              const referencedInSteps = stepReferencesById[innerStep.step.id] || [];
              sectionReferencedInSteps = [...sectionReferencedInSteps, ...referencedInSteps];

              const innerMeta = {
                ...innerStep.meta,
                error: stepHasError(innerStep.step),
                referencedInSteps,
              };

              innerHasError = innerMeta.error;

              return {
                step: innerStep.step,
                meta: innerMeta,
              };
            }),
          }
        : {
            ...item.step,
            meta: {
              ...item.meta,
              referencedInSteps: stepReferencesById[item.step.id] || [],
            },
          },
      meta: {
        ...item.meta,
        error: innerHasError || stepHasError(item.step),
        referencedInSteps: stepReferencesById[item.step.id] || [],
      },
    };

    if (isSectionStep) {
      stepListItem.meta.referencedInSteps = [
        ...(stepListItem.meta.referencedInSteps || []),
        ...sectionReferencedInSteps,
      ];

      const displayCriteriaConditionIds = getStepIdsUsedInDisplayCriteria(
        (item.step as IBuilderSectionWithStepListItem).displayCriteria,
      );

      displayCriteriaConditionIds.forEach((conditionId) => {
        stepReferencesById[conditionId] = [...(stepReferencesById[conditionId] || []), item.step.id];
      });
    }

    acc.unshift(stepListItem);
    return acc;
  }, []);

  return stepListItems;
};

export const mapStepListItemToBuilderStep = ({ step }: StepListItem): IBuilderStep | IBuilderSection => {
  if (isBuilderSection(step)) {
    return {
      ...step,
      steps: step.steps.map(({ step }) => step),
    };
  }
  return step as IBuilderStep;
};

export const generateUniqueSlug = ({
  name,
  prefix,
  existingSlugs = [],
}: {
  name: string;
  existingSlugs?: string[];
  /** End result will be prefixed by this value followed by NESTED_STEPS_ID_DELIMITER value before the generated id */
  prefix?: string;
}): string => {
  const { isNestedStepId: isAlreadyNestedStepId, stepId: parentId, nestedStepId: stepId } = getNestedStepIds(name);
  let slug = slugify(name);

  if (isAlreadyNestedStepId) {
    slug = name;
  } else if (prefix) {
    slug = createNestedStepId({ parentId: prefix, stepId: slug });
  }

  // Make sure this generated slug is unique
  while (existingSlugs.indexOf(slug) !== -1) {
    const randomString = Math.random().toString(36).substr(2, 3);

    if (isAlreadyNestedStepId) {
      const uniqueBaseSlug = `${slugify(stepId)}-${randomString}`;
      slug = createNestedStepId({ parentId, stepId: uniqueBaseSlug });
    } else {
      const uniqueBaseSlug = `${slugify(name)}-${randomString}`;
      slug = prefix ? createNestedStepId({ parentId: prefix, stepId: uniqueBaseSlug }) : uniqueBaseSlug;
    }
  }

  return slug;
};

export const validateFlaggingInputNumberCriteriaCondtions = (
  conditions: FlaggingCriteria<InputNumberOperators>['conditions'] | undefined,
): boolean =>
  conditions?.some((item) => {
    if (item.type === FilterTypes.GROUP) {
      return validateFlaggingInputNumberCriteriaCondtions(item.conditions);
    }

    return item.value === '' || !item.value;
  }) || false;

export const validateFlaggingInputChecklistCriteriaCondtions = (
  conditions: FlaggingCriteria<InputChecklistOperators>['conditions'] | undefined,
): boolean =>
  conditions?.some((item) => {
    if (item.type === FilterTypes.GROUP) {
      return validateFlaggingInputChecklistCriteriaCondtions(item.conditions);
    }

    return item.name === '' || !item.name;
  }) || false;

export const validateSectionDisplayCriterias = (step: IBuilderStep | IBuilderSectionWithStepListItem): boolean => {
  const stepSection = step as unknown as IBuilderSection;
  const conditions = stepSection.displayCriteria?.conditions;

  return (
    stepSection.steps.length === 0 ||
    validateFlaggingInputNumberCriteriaCondtions(conditions as FlaggingCriteria<InputNumberOperators>['conditions']) ||
    validateFlaggingInputChecklistCriteriaCondtions(
      conditions as FlaggingCriteria<InputChecklistOperators>['conditions'],
    )
  );
};

// eslint-disable-next-line complexity
export const stepHasError = (step: IBuilderStep | IBuilderSectionWithStepListItem): boolean => {
  if (step.type !== DocumentBuilderStepTypes.InfoMedia && !step.title) {
    return true;
  }

  switch (step.type) {
    case DocumentBuilderStepTypes.InfoMedia:
      return !step.media;
    case DocumentBuilderStepTypes.InputText:
      // eslint-disable-next-line no-case-declarations
      const maxCharacters = (step as IBuilderStepInputText).maxCharacters;

      return !!maxCharacters && String((step as IBuilderStepInputText).maxCharacters) === '';
    case DocumentBuilderStepTypes.ContainerSection:
      return validateSectionDisplayCriterias(step);
    case DocumentBuilderStepTypes.InputMedia:
      return !(step as IBuilderStepInputMedia).accept.length;
    case DocumentBuilderStepTypes.InputNumber:
      return (
        [String(step.decimalDigits), String(step.integerDigits)].includes('') ||
        !validateInputNumber(step.defaultValue, step.integerDigits, step.decimalDigits) ||
        validateFlaggingInputNumberCriteriaCondtions(step.flaggingCriteria?.conditions)
      );
    case DocumentBuilderStepTypes.InputChecklist:
      // eslint-disable-next-line no-case-declarations
      const checkStep = step as IBuilderStepInputChecklist;

      return (
        checkStep.options.length < 2 ||
        ['', '0'].includes(String(checkStep.minSelect)) ||
        (checkStep.minSelect ? checkStep.minSelect > checkStep.options.length : false) ||
        validateFlaggingInputChecklistCriteriaCondtions(checkStep.flaggingCriteria?.conditions)
      );
    case DocumentBuilderStepTypes.InputScancode:
      // eslint-disable-next-line no-case-declarations
      const inputScanCode = step as IBuilderStepInputScancode;
      return !inputScanCode.title && inputScanCode.conditions.some((item) => !item.value);
    case DocumentBuilderStepTypes.InfoText:
    default:
      return false;
  }
};

export const DocumentBuilderStepComponent: DocumentBuilderStepComponentType = {
  [DocumentBuilderStepTypes.InfoText]: ({ value, isSelected, isPreviewMode }, onChange) => (
    <BuilderStepInfoText
      value={value.step as IBuilderStepInfoText}
      onChange={onChange as (value: IBuilderStepInfoText) => void}
      isPreviewMode={isPreviewMode}
      isSelected={isSelected}
    />
  ),
  [DocumentBuilderStepTypes.InfoMedia]: ({ value }, onChange, onDeleteMedia) => (
    <BuilderStepInfoMedia
      value={value.step as IBuilderStepInfoMedia}
      onChange={onChange}
      onDeletedMedia={onDeleteMedia}
      meta={value.meta}
    />
  ),
  [DocumentBuilderStepTypes.InputText]: ({ value }) => (
    <BuilderStepInputText
      value={
        value as {
          step: IBuilderStepInputText;
        }
      }
    />
  ),
  [DocumentBuilderStepTypes.InputMedia]: ({ value }) => (
    <BuilderStepInputMedia value={value as { step: IBuilderStepInputMedia }} />
  ),
  [DocumentBuilderStepTypes.InputChecklist]: ({ value, isSelected }, onChange) => (
    <BuilderStepInputChecklist
      isSelected={!!isSelected}
      value={value.step as IBuilderStepInputChecklist}
      onChange={onChange}
      meta={value.meta}
    />
  ),
  [DocumentBuilderStepTypes.InputNumber]: ({ value }) => (
    <BuilderStepInputNumber value={value.step as IBuilderStepInputNumber} />
  ),
  [DocumentBuilderStepTypes.InputScancode]: ({ value }) => (
    <BuilderStepInputScancode value={value.step as IBuilderStepInputScancode} />
  ),
};

export const StepColorMapper: Record<DocumentBuilderStepTypes, string> = {
  [DocumentBuilderStepTypes.InfoText]: '#2660E6',
  [DocumentBuilderStepTypes.InfoMedia]: '#2660E6',
  [DocumentBuilderStepTypes.InputChecklist]: '#FE812A',
  [DocumentBuilderStepTypes.InputText]: '#1B90FF',
  [DocumentBuilderStepTypes.InputMedia]: '#AF4BE8',
  [DocumentBuilderStepTypes.InputNumber]: '#66BB6A',
  [DocumentBuilderStepTypes.InputScancode]: '#26C6DA',
  [DocumentBuilderStepTypes.ContainerSection]: '#78909C',
};

export const StepIconMapper: Record<DocumentBuilderStepTypes, ReactNode> = {
  [DocumentBuilderStepTypes.InfoText]: <TextFieldsIcon />,
  [DocumentBuilderStepTypes.InfoMedia]: <SubscriptionsOutlinedIcon />,
  [DocumentBuilderStepTypes.InputChecklist]: <ChecklistIcon />,
  [DocumentBuilderStepTypes.InputText]: <FormatColorTextIcon />,
  [DocumentBuilderStepTypes.InputMedia]: <AddPhotoAlternateOutlinedIcon />,
  [DocumentBuilderStepTypes.InputNumber]: <SvgIcon component={Icon123} inheritViewBox />,
  [DocumentBuilderStepTypes.InputScancode]: <QrCodeScannerIcon />,
  [DocumentBuilderStepTypes.ContainerSection]: <ViewAgendaOutlinedIcon />,
};

const NESTED_STEPS_ID_DELIMITER = '.';

export const createNestedStepId = ({ parentId, stepId }: { parentId: string; stepId: string }): string =>
  `${parentId}${NESTED_STEPS_ID_DELIMITER}${stepId}`;

export const getNestedStepIds = (id: string): { stepId: string; nestedStepId?: string; isNestedStepId: boolean } => {
  const [stepId, nestedStepId] = id.split(NESTED_STEPS_ID_DELIMITER);
  return { stepId, nestedStepId, isNestedStepId: nestedStepId !== undefined };
};
interface GenerateNewStepProps {
  stepType: DocumentBuilderStepTypes;
  /** If it's a Section step send parent id to prepend it */
  parentStepId?: string;
  existingSlugs?: string[];
}
export const generateNewStep = ({
  stepType,
  parentStepId,
  existingSlugs,
}: GenerateNewStepProps): IBuilderStep | IBuilderSectionWithStepListItem => {
  const stepBase = {
    id: generateUniqueSlug({ name: stepType, existingSlugs, prefix: parentStepId }),
    type: stepType,
    title: '',
    description: '',
  };
  switch (stepType) {
    case DocumentBuilderStepTypes.InfoText:
      return stepBase as IBuilderStepInfoText;
    case DocumentBuilderStepTypes.InfoMedia:
      return { ...stepBase } as IBuilderStepInfoMedia;
    case DocumentBuilderStepTypes.InputNumber:
      return {
        ...stepBase,
        integerDigits: 2,
        decimalDigits: 2,
        defaultValue: 0,
        required: true,
      } as IBuilderStepInputNumber;
    case DocumentBuilderStepTypes.InputChecklist:
      return {
        ...stepBase,
        options: [
          { label: '', value: '' },
          { label: '', value: '' },
          { label: '', value: '' },
        ],
        minSelect: 1,
        multiple: false,
        required: true,
      } as IBuilderStepInputChecklist;
    case DocumentBuilderStepTypes.InputMedia:
      return {
        ...stepBase,
        accept: [
          BuilderStepInputMediaAccepts.Image,
          BuilderStepInputMediaAccepts.Audio,
          BuilderStepInputMediaAccepts.Video,
        ],
        multiple: false,
        max: null,
        required: true,
      } as IBuilderStepInputMedia;
    case DocumentBuilderStepTypes.InputScancode:
      return {
        ...stepBase,
        conditions: [],
        allowManualInput: true,
        required: true,
      } as IBuilderStepInputScancode;
    case DocumentBuilderStepTypes.InputText:
      return {
        ...stepBase,
        maxCharacters: null,
        required: true,
      } as IBuilderStepInputText;
    case DocumentBuilderStepTypes.ContainerSection:
      return {
        ...stepBase,
        steps: [],
        displayCriteria: null,
        roles: [],
        title: 'Section name',
      } as IBuilderSectionWithStepListItem;
    default:
      throw new Error(`${stepType} not implemented yet`);
  }
};

export const getExistingStepIdList = (steps: StepListItem<IBuilderStep | IBuilderSectionWithStepListItem>[]) =>
  steps.map(({ step }) => step.id);

/**
 * Remove `_id` from all steps else backend will return an error.
 * Also, change the id of each step to a unique slug based on the title or type of the step.
 * (id's have to be in human-readable format thats why we are relying on the title or type of the step)
 * Update all the references made towards the old id's to the new id's (displayCriteria)
 */
export const prepareDocumentVersionUpdatePayload = (document: DocumentVersionUpdateBody) => {
  const preparedDocument = cloneDeep(document);
  /** Map of old id's to new id's */
  const idChangeMap: Record<string, string> = {};

  if (preparedDocument.steps) {
    preparedDocument.steps = preparedDocument.steps.map(({ _id, ...step }, index) => {
      const stepIdBasedOnTheTitle = generateUniqueSlug({
        name: `${step.title ?? step.type}-${index}`,
        existingSlugs: preparedDocument.steps?.map((s) => s.id).filter((id) => id !== step.id) ?? [],
      });

      idChangeMap[step.id] = stepIdBasedOnTheTitle;

      if (isBuilderSection(step as IBuilderSection)) {
        const typesSteps = step as IBuilderSection;

        return {
          ...step,
          id: stepIdBasedOnTheTitle,
          steps: typesSteps.steps?.map(({ _id, ...subStep }) => {
            const nestedStepIdBasedOnTheTitle = generateUniqueSlug({
              prefix: stepIdBasedOnTheTitle,
              name: subStep.title ?? subStep.type,
              existingSlugs: typesSteps.steps?.map((s) => s.id).filter((id) => id !== subStep.id) ?? [],
            });

            idChangeMap[subStep.id] = nestedStepIdBasedOnTheTitle;

            return {
              ...subStep,
              id: nestedStepIdBasedOnTheTitle,
            };
          }),
          displayCriteria: typesSteps.displayCriteria
            ? {
                ...typesSteps.displayCriteria,
                conditions: typesSteps.displayCriteria.conditions.map((conditionGroup) => {
                  if (isFilterGroup(conditionGroup)) {
                    return {
                      ...conditionGroup,
                      conditions: conditionGroup.conditions.map((condition) => {
                        if (isFilterCondition(condition)) {
                          return {
                            ...condition,
                            name: idChangeMap[condition.name],
                          };
                        }

                        return condition;
                      }),
                    };
                  }

                  return conditionGroup;
                }),
              }
            : null,
        };
      }

      return { ...step, id: stepIdBasedOnTheTitle };
    });
  }

  return preparedDocument;
};
