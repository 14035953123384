import { MetaInterface } from '../common';
import { DatabaseTypes } from './DatabaseTypes.enum';
import { IDatabaseColumn } from './DatabaseColumn.interface';

export enum DatabaseExternalIdColumnTypes {
  DOUBLE = 'Double',
  STRING = 'String',
  INTEGER = 'Integer',
}

export const COLUMN_ARRAY_TYPE_SUFFIX = 'Array';

export interface IDatabaseHistoryConfig {
  enabled: boolean;
  expiresInSec: number;
}

export class IDatabase {
  _id: string;

  history: IDatabaseHistoryConfig;

  externalIdType: DatabaseExternalIdColumnTypes;

  type: DatabaseTypes;

  name: string;

  slug: string;

  readOnlyRoles: string[];

  readWriteRoles: string[];

  columns: IDatabaseColumn[];

  meta: MetaInterface;
}
