import React, { FC, useEffect, useState } from 'react';
import { WuiFileInput, WuiFileInputValue } from '@uiKit/WuiFileInput';
import { WuiMediaDisplay } from '@uiKit';
import type { IBuilderStepInfoMedia } from '@workerbase/domain/document';
import { StepListItemMeta } from 'components/DocumentBuilder/types';

interface BuilderStepInfoMediaProps {
  value: IBuilderStepInfoMedia;
  meta: StepListItemMeta;
  onChange: (value: IBuilderStepInfoMedia) => void;
  onDeletedMedia: (mediaId: string) => void;
}

export const BuilderStepInfoMedia: FC<BuilderStepInfoMediaProps> = ({ value, meta, onChange, onDeletedMedia }) => {
  useEffect(() => {
    if (value.media) {
      setMedias([{ name: value.media.fileName, id: value.media._id, type: value.media.mimeType }]);
    }
  }, [value.media]);

  const [medias, setMedias] = useState<WuiFileInputValue[]>([]);

  const handleMediaChange = (files: WuiFileInputValue[]) => {
    setMedias(files);
    const [media] = files;
    onChange({ ...value, media: { _id: media.id, mimeType: media.type, fileName: media.name } });
  };

  return (
    <>
      {meta.error || meta.selected ? (
        <WuiFileInput
          error={meta.error}
          accept={['video/*', 'audio/*', 'image/*']}
          onChange={handleMediaChange}
          onDelete={(mediaId: string) => onDeletedMedia(mediaId)}
          medias={medias}
        />
      ) : (
        !!medias.length && <WuiMediaDisplay id={medias[0].id} disableExpand />
      )}
    </>
  );
};
