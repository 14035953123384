import React, { FC } from 'react';
import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import type { IBuilderStepInputScancode } from '@workerbase/domain/document';
import { Formik, Form, FormikProps } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import { FormikToggle } from 'components/Input/Field';
import { FormikObserver } from 'utils/formik';
import { type InputBarcodeRegex } from '@workerbase/types/domain/workinstruction/step';
import { BarcodeFiltersInput } from 'components/Input/BarcodeFiltersInput';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';

interface BuilderStepInputScancodeSettingsProps {
  value: IBuilderStepInputScancode;
  onChange: (value: IBuilderStepInputScancode) => void;
}

interface InputScancodeSettingsFormValues {
  conditions: InputBarcodeRegex[];
  openConditions: boolean;
  allowManualInput: boolean;
  required: boolean;
}

const createValidationSchema = (intl: IntlShape) =>
  Yup.object({
    conditions: Yup.array().test(
      'conditions',
      intl.formatMessage({ id: 'document.builder.input-scancode.error-message' }),
      function (conditions) {
        const hasError = conditions?.some((item) => !item.value);

        return hasError
          ? this.createError({
              path: 'conditions',
              message: intl.formatMessage({ id: 'document.builder.input-scancode.error-message' }),
            })
          : true;
      },
    ),
    allowManualInput: Yup.boolean().required(),
    required: Yup.boolean().required(),
  });

export const BuilderStepInputScancodeSettings: FC<BuilderStepInputScancodeSettingsProps> = ({ value, onChange }) => {
  const intl = useIntl();

  const handleChange =
    (formik: FormikProps<InputScancodeSettingsFormValues>) => (values: InputScancodeSettingsFormValues) => {
      if (!values.openConditions && values.conditions?.length) {
        formik.setFieldValue('conditions', []);
      }
      onChange({
        ...value,
        allowManualInput: values.allowManualInput,
        conditions: values.conditions,
        required: values.required,
      });
    };

  return (
    <Formik<InputScancodeSettingsFormValues>
      initialValues={{
        openConditions: !!value.conditions?.length,
        conditions: value.conditions,
        allowManualInput: value.allowManualInput,
        required: value.required,
      }}
      validationSchema={createValidationSchema(intl)}
      onSubmit={() => {}}
      validateOnChange
    >
      {(formik) => (
        <FormBuilderSettingsTabsWrapper
          header={
            <Typography variant="h6">
              {intl.formatMessage({ id: 'document.builder.input-scancode.settings' })}
            </Typography>
          }
          general={
            <Stack spacing={1} component={Form} id="note-form">
              <FormikObserver<InputScancodeSettingsFormValues> onChange={handleChange(formik)} />
              <FormikToggle name="openConditions" label="from.scancode.specific" />
              <FormHelperText>{intl.formatMessage({ id: 'document.builder.input-scancode.info-text' })}</FormHelperText>
              {formik.values.openConditions && (
                <BarcodeFiltersInput
                  direction="column"
                  filters={formik.values.conditions ?? []}
                  onChange={(updatedFilters) => {
                    formik.setFieldValue('conditions', updatedFilters);
                  }}
                  afterDelete={() => {}}
                />
              )}
              {!!formik.errors.conditions && (
                <FormHelperText error={!!formik.errors.conditions}>
                  <>{formik.errors.conditions}</>
                </FormHelperText>
              )}
              <Divider />
              <FormikToggle name="allowManualInput" label="form.scancode.allow-manual-mobile" />
              <Divider />
              <FormikToggle name="required" label="form-validation.required" />
            </Stack>
          }
        />
      )}
    </Formik>
  );
};
