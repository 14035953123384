import React, { FC, useEffect, useState } from 'react';
import { Stack, Typography, Box, SxProps, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useApiRequest } from 'hooks/useRequest';
import { WuiFileInput, WuiFileInputValue } from '@uiKit/WuiFileInput';
import { deleteInboxDocument, uploadInboxDocument } from 'services/networking/documents';
import { InboxUploadResponse } from '@workerbase/api/http/document';

interface UploadFilesProps {
  accept?: string[];
  sx?: SxProps<Theme>;
  onLoading?: (isLoading: boolean) => void;
}

export const UploadFiles: FC<UploadFilesProps> = ({ sx, accept = ['image/*', 'video/*', 'audio/*'], onLoading }) => {
  const intl = useIntl();
  const [files, setFiles] = useState<WuiFileInputValue[]>([]);
  const [filesIdWithInboxDocument, setFilesIdWithInboxDocument] = useState<string[]>([]);
  const [inboxDocuments, setInboxDocuments] = useState<InboxUploadResponse[]>([]);

  const uploadInboxDocumentRequest = useApiRequest(uploadInboxDocument, {
    manual: true,
    onSuccess: (response) => {
      setInboxDocuments((prevInboxDocuments) => [...prevInboxDocuments, response.data]);
    },
  });

  const handleFileUpload = async (newFiles: WuiFileInputValue[]) => {
    setFiles(newFiles);
  };

  const handleFileDelete = (fileId: string) => {
    const documentId = inboxDocuments.find((inboxDocument) => inboxDocument.fileId === fileId)?._id;
    if (documentId) {
      deleteInboxDocument(documentId);
    }
  };

  useEffect(() => {
    const createDocumentsForNewFiles = async () => {
      files.forEach((file) => {
        if (!filesIdWithInboxDocument.includes(file.id)) {
          setFilesIdWithInboxDocument((prevFilesId) => [...prevFilesId, file.id]);
          uploadInboxDocumentRequest.run(file.id);
        }
      });
    };
    createDocumentsForNewFiles();
  }, [files]);

  return (
    <Stack component="section" px={4} pb={8} sx={sx}>
      <Typography mb={3} variant="h6">
        {intl.formatMessage({ id: 'documents.upload-files.title' })}{' '}
        <Box component="span" color="grey.500">
          {`${inboxDocuments.length}/${files.length}`}
        </Box>
      </Typography>
      <WuiFileInput
        accept={['application/pdf']}
        onChange={handleFileUpload}
        onDelete={handleFileDelete}
        multiple
        medias={files}
        googleDriveUploadEnabled
      />
    </Stack>
  );
};
