import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import { Sorting, ListConfigs } from '@workerbase/types/ListConfig';

import { Role } from 'services/types/Role';

import { RolesActions } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import { updateSorting, updateListConfig } from '../common/ListConfig/reducers';

export type RolesState = Readonly<{
  rolesById: { [key: string]: Role };
  listConfigs: ListConfigs;
  currentListItemsIds: string[];
  errorMessage: string | null;
  isLoading: boolean;
}>;

export const initialState: RolesState = {
  rolesById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
    sorting: { selector: 'name', sortDirection: SortOrder.ASC },
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'description',
        omit: false,
      },
      {
        selector: 'deviceApps',
        omit: false,
      },
    ],
  },
  currentListItemsIds: [],
  errorMessage: null,
  isLoading: false,
};

const reducer = (state: RolesState = initialState, action: AnyAction): RolesState => {
  switch (action.type) {
    case RolesActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case RolesActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    case RolesActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
