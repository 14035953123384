import { UserListConfigTypes } from '@workerbase/domain/user';
import { SortOrder } from '@workerbase/domain/common';

export interface ListConfigProperties {
  resourceId?: string;
  properties?: Property[];
}

export interface ListConfigSorting {
  sorting?: Sorting;
}

export interface ListConfigPagination {
  pagination?: {
    totalItems?: number;
    currentPage: number;
    itemsPerPage: number;
  };
}

export interface ListConfigFiltering {
  filtering: {
    searchTerms: string;
  };
}

export interface ListConfigTabs {
  tabs?: Tabs;
}

export interface ListConfigs
  extends ListConfigProperties,
    ListConfigPagination,
    ListConfigSorting,
    ListConfigFiltering,
    ListConfigTabs {}

export const shouldDisplayLinks = (listConfigs: ListConfigs) => {
  if (listConfigs.properties) {
    for (const property of listConfigs.properties) {
      if (property.selector === PropertySelectors.LINKS && !property.omit) {
        return true;
      }
    }
  }
  return false;
};

export interface Property {
  selector: string;
  omit: boolean;
}

export enum PropertySelectors {
  LINKS = 'links',
}

export interface Pagination {
  page: number;
  perPage: number;
}
export interface Sorting {
  selector: string;
  sortDirection: SortOrder;
}

export interface Tabs {
  selectedTabKey: string;
  categoryKey: string;
  categories: string[];
  options: TabOption[];
}

export enum TabActiveColorType {
  DEFAULT = 'default',
  DANGER = 'danger',
  GREEN = 'green',
  GRAY = 'gray',
}

export interface TabOption {
  key: string;
  count: number;
  titleTranslationKey?: string;
  title?: string;
  activeColor: TabActiveColorType;
}

export interface ListConfigApi {
  resourceId?: string;
  listType: UserListConfigTypes;
  config: ListConfigSorting & ListConfigProperties;
}

export interface CategoryOption {
  key: string;
  titleTranslationKey: string;
  count: number;
  activeColor: TabActiveColorType;
}
