import { ValueOf } from '../utils/TS';
import { ComparingConditionOperator, DirectConditionOperator } from '../rule';

export const TaskFilterConditionOperators = Object.freeze({
  EQUAL: ComparingConditionOperator.EQUAL,
  DOES_NOT_EQUAL: ComparingConditionOperator.DOES_NOT_EQUAL,
  CONTAINS: ComparingConditionOperator.CONTAINS,
  DOES_NOT_CONTAIN: ComparingConditionOperator.DOES_NOT_CONTAIN,
  GREATER_THAN: ComparingConditionOperator.GREATER_THAN,
  GREATER_OR_EQUAL: ComparingConditionOperator.GREATER_OR_EQUAL,
  LESS_THAN: ComparingConditionOperator.LESS_THAN,
  LESS_OR_EQUAL: ComparingConditionOperator.LESS_OR_EQUAL,
  REGEX: ComparingConditionOperator.REGEX,
  BEGINS: ComparingConditionOperator.BEGINS,
  DOES_NOT_BEGIN: ComparingConditionOperator.DOES_NOT_BEGIN,
  ENDS: ComparingConditionOperator.ENDS,
  DOES_NOT_END: ComparingConditionOperator.DOES_NOT_END,
  TRUE: DirectConditionOperator.TRUE,
  FALSE: DirectConditionOperator.FALSE,
  EMPTY: DirectConditionOperator.EMPTY,
  IS_NOT_EMPTY: DirectConditionOperator.IS_NOT_EMPTY,
  EXISTS: DirectConditionOperator.EXISTS,
  DOES_NOT_EXIST: DirectConditionOperator.DOES_NOT_EXIST,
});

export type TaskFilterConditionOperatorsType = ValueOf<typeof TaskFilterConditionOperators>;
