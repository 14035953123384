import { call, put, select, all } from 'redux-saga/effects';

import { UserListConfigTypes } from '@workerbase/domain/user';
import { ListConfigs, ListConfigApi } from '@workerbase/types/ListConfig';

import { getListConfig, syncListConfig } from 'services/networking/users';
import { LoggedUser } from 'services/types/User';
import { getUser } from '@redux/Login/selectors';

import { handleRequestError } from '../actions';

import { UpdateListConfigAction, updateListConfigFactory } from './actions';
import { listTypeToGetListConfigSelectorMapping, listTypeToUpdateListConfigActionMapping } from './utils';

export function* updateListConfigSaga(action: UpdateListConfigAction) {
  const loggedUser: LoggedUser | null = yield select(getUser);
  if (!loggedUser) {
    return;
  }

  // get store name from action eg 'users' from '@@users/UPDATE_SORTING' or '@@users/UPDATE_LISTCONFIG_PROPERTIES'
  const actionParsed: RegExpMatchArray | null = action.type.match(/@@(.*)\//);
  if (!actionParsed) {
    return;
  }
  const listType = actionParsed[1] as UserListConfigTypes;

  const listConfigs = (yield select(
    listTypeToGetListConfigSelectorMapping[listType],
    action?.payload?.resourceId,
  )) as ListConfigs;

  try {
    yield call(syncListConfig, loggedUser.id, {
      resourceId: action?.payload?.resourceId,
      listType,
      config: {
        sorting: listConfigs.sorting,
        properties: listConfigs.properties,
      },
    });
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* getListConfigSaga(action) {
  const userId = action.payload.userId;
  try {
    const response: ListConfigApi[] = yield call(getListConfig, userId);
    yield all(
      response.map((listConfig) => {
        const actionType: string = listTypeToUpdateListConfigActionMapping[listConfig.listType];
        if (!actionType) {
          console.warn(`No appropriate listConfig action for action type: ${listConfig.listType}`);

          return;
        }

        const updateListConfigAction = updateListConfigFactory(actionType);

        return put(
          updateListConfigAction({
            ...listConfig.config,
            resourceId: listConfig?.resourceId,
          }),
        );
      }),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}
