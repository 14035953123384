import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import { PaginatedResponse } from '@workerbase/types/api/pagination';
import { Sorting } from '@workerbase/types/ListConfig';
import { ShiftPlanGET, ShiftPlanPOST, ShiftPlanPUT } from '@workerbase/types/api/shiftPlans';
import { api } from './api';

const SHIFT_PLANS_ENDPOINT = '/api/v1/shiftplans';

type GetShiftPlans = (options: {
  page: number;
  perPage: number;
  sorting?: Sorting;
}) => Promise<PaginatedResponse<ShiftPlanGET[]>>;

export const getShiftPlans: GetShiftPlans = async ({ page, perPage, sorting }) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
  };

  const { data } = await api.get<PaginatedResponse<ShiftPlanGET[]>>(SHIFT_PLANS_ENDPOINT, { params });

  return data;
};

export const deleteShiftPlanById = async (shiftPlanId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${SHIFT_PLANS_ENDPOINT}/${shiftPlanId}`);

  return true;
};

export const createShiftPlan = async (shiftPlan: ShiftPlanPOST): Promise<ShiftPlanGET> => {
  const {
    data: { data },
  } = await api.post<{ data: ShiftPlanGET }>(SHIFT_PLANS_ENDPOINT, shiftPlan);

  return data;
};

export const updateShiftPlanById = async (shiftPlanId: string, shiftPlan: ShiftPlanPUT): Promise<ShiftPlanGET> => {
  const {
    data: { data },
  } = await api.put<{ data: ShiftPlanGET }>(`${SHIFT_PLANS_ENDPOINT}/${shiftPlanId}`, shiftPlan);

  return data;
};
