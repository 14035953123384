// eslint-disable-next-line max-classes-per-file
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { Equals, IsEnum, IsOptional, IsString, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { CurrentTaskAction } from '@workerbase/domain/task';
import { NavigationBehaviour } from '../../NavigationBehaviour';
import { StepActionTypes } from '../../StepActionTypes';
import { PostActionTypes } from '../../PostActionTypes';
import { ClassValidatorOptions } from '../utils/ClassValidatorOptions';
import { NextStepPosition } from '../../NextStepPosition';

export interface PostActionable {
  postAction?: PostActionTypes;
}

@Schema({ _id: false, discriminatorKey: 'type', overwriteModels: true })
export class StepActionBase {
  @Prop()
  @IsString()
  @IsOptional()
  id?: string;

  @Prop({ type: String, enum: Object.values(StepActionTypes), required: true, $skipDiscriminatorCheck: true })
  @IsEnum(StepActionTypes, ClassValidatorOptions.getEnumErrorMessageOptions(StepActionTypes))
  type: StepActionTypes;
}

export const StepActionBaseSchema = SchemaFactory.createForClass(StepActionBase);

@Schema({ _id: false })
export class ChainableAction extends StepActionBase {
  @Prop({ type: String, enum: Object.values(CurrentTaskAction), required: true })
  @IsEnum(CurrentTaskAction, ClassValidatorOptions.getEnumErrorMessageOptions(CurrentTaskAction))
  currentTaskAction: CurrentTaskAction;
}

@Schema({ _id: false })
class StepEditorContext {
  @Prop({ type: String, enum: Object.values(NavigationBehaviour), default: NavigationBehaviour.SPECIFIC_STEP })
  @IsEnum(NavigationBehaviour, ClassValidatorOptions.getEnumErrorMessageOptions(NavigationBehaviour))
  navigationBehaviour: NavigationBehaviour;

  @Prop({ type: String, enum: Object.values(NextStepPosition) })
  @IsEnum(NextStepPosition, ClassValidatorOptions.getEnumErrorMessageOptions(NextStepPosition))
  @IsOptional()
  nextStepPosition?: NextStepPosition; // only used if navigationBehaviour equals SPECIFIC_STEP
}

@Schema({ _id: false })
export class StepActionNextStep extends StepActionBase {
  @Equals(StepActionTypes.STEP)
  type: StepActionTypes.STEP;

  @Prop()
  @IsString()
  stepId: string; // stepId or variable

  @Prop({ type: SchemaFactory.createForClass(StepEditorContext) })
  @ValidateNested()
  @Type(() => StepEditorContext)
  editorContext?: StepEditorContext;
}

@Schema({ _id: false })
export class StepActionClose extends StepActionBase {
  @Equals(StepActionTypes.CLOSE)
  type: StepActionTypes.CLOSE;

  @Prop({ type: String, enum: Object.values(PostActionTypes) })
  @IsOptional()
  @IsEnum(PostActionTypes, ClassValidatorOptions.getEnumErrorMessageOptions(PostActionTypes))
  postAction?: PostActionTypes;
}

@Schema({ _id: false })
export class StepActionSuspend extends StepActionBase {
  @Equals(StepActionTypes.SUSPEND)
  type: StepActionTypes.SUSPEND;

  @Prop({ type: String, enum: Object.values(PostActionTypes) })
  @IsOptional()
  @IsEnum(PostActionTypes, ClassValidatorOptions.getEnumErrorMessageOptions(PostActionTypes))
  postAction?: PostActionTypes;
}

@Schema({ _id: false })
export class StepActionFinish extends StepActionBase {
  @Equals(StepActionTypes.FINISH)
  type: StepActionTypes.FINISH;

  @Prop({ type: String, enum: Object.values(PostActionTypes) })
  @IsOptional()
  @IsEnum(PostActionTypes, ClassValidatorOptions.getEnumErrorMessageOptions(PostActionTypes))
  postAction?: PostActionTypes;
}

@Schema({ _id: false })
export class StepActionNextTask extends ChainableAction {
  @Equals(StepActionTypes.TASK)
  type: StepActionTypes.TASK;

  @Prop()
  @IsString()
  taskId: string;

  @Prop()
  @IsString()
  @IsOptional()
  initialStepId?: string;
}

@Schema({ _id: false })
export class StepActionNextWI extends ChainableAction {
  @Equals(StepActionTypes.WORKINSTRUCTIONS)
  type: StepActionTypes.WORKINSTRUCTIONS;

  @Prop()
  @IsString()
  workinstructionsId: string;

  @Prop()
  @IsString()
  @IsOptional()
  initialStepId?: string;
}

@Schema({ _id: false })
export class StepActionNextApp extends ChainableAction {
  @Equals(StepActionTypes.APP)
  type: StepActionTypes.APP;

  @Prop()
  @IsString()
  appId: string; // "io.workerbase.app.xxxx|com.microsoft.teams",
}

@Schema({ _id: false })
export class StepActionLoadStep extends StepActionBase {
  @Equals(StepActionTypes.LOAD_NEXT_STEP)
  type: StepActionTypes.LOAD_NEXT_STEP;

  @Prop()
  @IsString()
  loadNextStepFromUrl: string;
}

@Schema({ _id: false })
export class StepActionLoadAction extends StepActionBase {
  @Equals(StepActionTypes.LOAD_NEXT_ACTION)
  type: StepActionTypes.LOAD_NEXT_ACTION;

  @Prop()
  @IsString()
  loadNextActionFromUrl: string;
}

export const StepActionClassByActionType: { [type in StepActionTypes]: any } = {
  [StepActionTypes.STEP]: StepActionNextStep,
  [StepActionTypes.TASK]: StepActionNextTask,
  [StepActionTypes.WORKINSTRUCTIONS]: StepActionNextWI,
  [StepActionTypes.APP]: StepActionNextApp,
  [StepActionTypes.LOAD_NEXT_STEP]: StepActionLoadStep,
  [StepActionTypes.LOAD_NEXT_ACTION]: StepActionLoadAction,
  [StepActionTypes.CLOSE]: StepActionClose,
  [StepActionTypes.SUSPEND]: StepActionSuspend,
  [StepActionTypes.FINISH]: StepActionFinish,
};

type IStepActionDiscriminator = { [type in StepActionTypes]: mongoose.Schema };

export const StepActionDiscriminator: IStepActionDiscriminator = Object.keys(StepActionClassByActionType).reduce(
  (acc, stepActionType) => {
    acc[stepActionType] = SchemaFactory.createForClass(StepActionClassByActionType[stepActionType]);
    return acc;
  },
  {} as IStepActionDiscriminator,
);

export type StepAction =
  | StepActionClose
  | StepActionSuspend
  | StepActionFinish
  | StepActionNextStep
  | StepActionNextTask
  | StepActionNextWI
  | StepActionNextApp
  | StepActionLoadStep
  | StepActionLoadAction;
