import React from 'react';
import { SelectableVariable, VariableDataTypes } from '@workerbase/types/Variable';
import styles from './VariablesMentionSuggestion.module.scss';

const getDataTypeClassNames = (type: VariableDataTypes) => {
  let classNames = styles.dataType;
  if ([VariableDataTypes.ARRAY, VariableDataTypes.OBJECT].includes(type)) {
    classNames += ` ${styles.arrayOrObject}`;
  }
  if (VariableDataTypes.UNKNOWN === type) {
    classNames += ` ${styles.unknown}`;
  }
  return classNames;
};

export const VariablesMentionSuggestion: React.FC<SelectableVariable> = ({ name, datatype, lastValue = 'null' }) => (
  <div className={styles.container}>
    <div className={styles.variable}>{name}</div>
    <div className={getDataTypeClassNames(datatype)}>{datatype}</div>
    <div className={styles.lastValue}>{lastValue}</div>
  </div>
);
