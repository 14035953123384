import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import type {
  IBuilderStepInputCheckListOption,
  IBuilderStepInputChecklist,
  IDocumentBuilderStepInputChecklistResult,
} from '@workerbase/domain/document';
import { WuiActionButton } from '@uiKit/WuiButtons/WuiActionButton';
import { generateUniqueSlug } from 'components/DocumentBuilder/utils';
import { BuilderStepInputChecklistOption } from './BuilderStepInputChecklistOption';

interface BuilderStepInputChecklistProps {
  value: IBuilderStepInputChecklist;
  result?: IDocumentBuilderStepInputChecklistResult;
  meta: {
    error: boolean;
  };
  isSelected: boolean;
  onChange: (value: IBuilderStepInputChecklist) => void;
}

export const BuilderStepInputChecklist: FC<BuilderStepInputChecklistProps> = ({
  value,
  result,
  meta,
  onChange,
  isSelected,
}) => {
  const intl = useIntl();

  const handleDelete = (index: number) => () => {
    const newOptions = [...value.options];
    newOptions.splice(index, 1);
    onChange({ ...value, options: newOptions });
  };

  const handleChange = (index: number) => (option: IBuilderStepInputCheckListOption) => {
    const newOptions = [...value.options];

    const slug = generateUniqueSlug({
      name: option.label,
      existingSlugs: value.options.map((op) => op.value),
    });

    const newOption = {
      label: option.label,
      value: value.options[index]?.value !== '' ? value.options[index]?.value : slug,
    };
    newOptions.splice(index, 1, newOption);
    onChange({ ...value, options: newOptions });
  };

  const addOption = () => {
    onChange({
      ...value,
      options: [
        ...value.options,
        {
          value: '',
          label: '',
        },
      ],
    });
  };

  const options = React.useMemo(() => value.options.map((item, index) => ({ ...item, id: index })), [value.options]);

  return (
    <Box>
      {options.map((option, index) => (
        <BuilderStepInputChecklistOption
          key={option.id}
          index={index}
          value={option}
          isChecked={!!result?.value?.some((element) => element.value === option.value)}
          multiple={value.multiple}
          isEditMode={isSelected}
          onChange={handleChange(index)}
          onDelete={handleDelete(index)}
        />
      ))}
      {isSelected && (
        <WuiActionButton sx={{ mt: 1 }} onClick={addOption}>
          {intl.formatMessage({ id: 'global.add-option' })}
        </WuiActionButton>
      )}
    </Box>
  );
};
